import React, { Suspense, setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';
import reportWebVitals from './reportWebVitals';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
const App = React.lazy(() => import('./App'));
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} />

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#3399ff',
  color: 'white'
}

setGlobal({
  cart: [],
  domain: '',
  hosting: '',
  current: 0
})

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spin indicator={antIcon} style={style} />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
